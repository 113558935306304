import { mapState, mapGetters } from 'vuex'
import ReCaptcha from '~/mixins/recaptcha'
import fingerprint from '~/mixins/fingerprint'

export default {
    mixins: [fingerprint, ReCaptcha],
    data() {
        return {
            loginType: 'phone',
            form: {
                player_id: '',
                phone: '',
                password: '',
                reg_fingerprint: '',
            },
            valid: true,
            loading: false,
            showPassword: false,
            rememberMe: true,
            country: 'LA',
            isRequireRule: true,
        }
    },
    computed: {
        ...mapState({
            isApp: (state) => state.app.isApp,
        }),
        ...mapGetters({
            getRegistrationSuspendByCurrency:
                'settings/getRegistrationSuspendByCurrency',
        }),
        formRule() {
            return {
                phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
                password: [
                    (v) => !!v || this.tl('passwordIsRequired'),
                    (v) =>
                        (v && v.length >= 6) || this.tl('passwordGreaterThan'),
                ],
                username: [
                    (v) => !!v || this.tl('enterYourPreferredPlayerId'),
                    (v) => (v && v.length >= 5) || this.tl('min5Length'),
                    (v) => (v && v.length <= 12) || this.tl('max12Length'),
                    (v) =>
                        /^(?!_)(?!.*_$)[a-zA-Z0-9_]+$/.test(v) ||
                        this.tl('invalid'),
                ],
            }
        },
    },
    async mounted() {
        const username = this.$cookie.get('un')
        const phoneNumber = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')
        if (username) this.form.player_id = username
        if (phoneNumber) this.form.phone = phoneNumber
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption

        this.form.reg_fingerprint = await this.visitorId()
    },
    methods: {
        inputPlayerId(e) {
            this.form.player_id = e.trim().toLowerCase()
        },
        async login() {
            // Set login option, for next Login
            this.$cookie.set('login_option', this.loginType)

            this.loading = true

            await this.$refs.form.validate()

            if (this.valid) {
                try {
                    if (this.loginType === 'username') {
                        delete this.form.phone
                    } else if (this.loginType === 'phone') {
                        delete this.form.player_id
                    } else {
                        return this.$toast.success('Wrong login option')
                    }

                    this.form.rememberMe = this.rememberMe
                    this.form.recaptchaToken = await this.getRecaptchaToken()
                    const response = await this.$store.dispatch(
                        'auth/login',
                        this.form
                    )
                    if (!response) {
                        this.resetRecaptcha()
                    }
                } catch (error) {}
            }
            this.loading = false
        },
        changeRequiredRule(rule) {
            if (this.loginType === 'username') {
                this.isRequireRule = false
            } else {
                this.isRequireRule = rule
            }
        },
    },
}
