import { mapGetters, mapState } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import { PLAYER_REGEX } from '~/utils/constants'
import ReCaptcha from '~/mixins/recaptcha'

export default {
    mixins: [fingerprint, ReCaptcha],
    data() {
        return {
            loginType: 'username',
            currentPage: 'login',
            accountHolderLoading: false,
            acceptCondition: true,
            showPassword: false,
            formValid: false,
            formData: {
                password: '',
                reg_fingerprint: '',
                currency: 'THB',
                phone: '',
                bank_id: '',
                bank_account_number: '',
                account_holder_name: '',
                recaptchaToken: '',
                language: 'th-TH',
                country_code: 'LA',
                player_id: '',
            },
            accountNameReadonly: true,
            showAccountHolderNameInstruction: false,
            suspendRegistrationLoading: false,
            registrationOptionLoading: false,
            currencyIsSuspended: false,
            registrationOption: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank
            currency: [],
            isRequireRule: true,
            registerLoading: false,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapState({
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
            operationSetting: (state) => state.settings.operationSetting,
        }),
        ...mapGetters({
            banks: 'banks/banks',
            supportCountries: 'themes/settings/supportCountry',
            isManualInputUsernameAvailableForRegistration:
                'settings/isManualInputUsernameAvailableForRegistration',
        }),
        formRule() {
            const playerBankAccountRegex = /^[\p{L}\p{Mark}.\s]+$/u
            return {
                password: [
                    (v) => !!v || this.tl('passwordIsRequired'),
                    (v) =>
                        (v && v.length >= 6) || this.tl('passwordGreaterThan'),
                ],
                currency: [(v) => !!v || `${this.tl('currencyIsRequired')}`],
                country: [(v) => !!v || `${this.tl('countryIsRequired')}`],
                phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
                bank_id: [(v) => !!v || `${this.tl('bankIsRequired')}`],
                bank_account_number: [
                    (v) => !!v || `${this.tl('accountNumberIsRequired')}`,
                    (v) =>
                        /^[\d\s]+$/.test(v) ||
                        `${this.tl('accountNumberShouldContainOnlyNumber')}`,
                ],

                account_holder_name: [
                    (v) => !!v || `${this.tl('accountHolderNameIsRequired')}`,
                    (v) =>
                        playerBankAccountRegex.test(v) ||
                        `${this.tl('accountHolderName')} ${this.tl(
                            'shouldBeAlphabetsAndDotsOnly'
                        )}`,
                ],
                bank_name: [(v) => !!v || `${this.tl('bankNameIsRequired')}`],
                agree: [(v) => !!v || ``],
                player_id: [(v) => !!v || `${this.tl('playerIdIsRequired')}`],
            }
        },
        operationSettingRegFlow() {
            if (this.operationSetting?.reg_flow) {
                return parseInt(this.operationSetting.reg_flow)
            }
            return 1
        },
        displayBankInformationForRegistration() {
            return this.operationSettingRegFlow === 1
        },
    },
    async mounted() {
        this.$store.dispatch('banks/banks', {})
        this.formData.reg_fingerprint = await this.visitorId()
        const countryCode = this.$cookie?.get('country_code')
        this.formData.country_code = countryCode
    },
    methods: {
        async getAccountHolderName() {
            // Check if the bank is supported smpg_status, if not => do not fetch account holder name
            const findBank = this.banks.find(
                (el) => el.id === this.formData.bank_id
            )
            if (findBank?.smpg_status === 0) {
                this.accountNameReadonly = false
                return
            }
            if (!this.formData.bank_id || !this.formData.bank_account_number)
                return
            if (!/^[\d\s]+$/.test(this.formData.bank_account_number)) {
                return
            }
            this.accountHolderLoading = true
            const result = await this.$store.dispatch(
                'themes/player/getBankAccountHolderName',
                {
                    bankId: this.formData.bank_id,
                    accountNo: this.formData.bank_account_number,
                }
            )
            this.accountHolderLoading = false
            if (result) {
                if (!result.requireManualInput) {
                    this.formData.account_holder_name = result?.fullName
                    this.accountNameReadonly = true
                    this.showAccountHolderNameInstruction = false
                    return
                }
                this.accountNameReadonly = false
                this.showAccountHolderNameInstruction = true
            }
            this.formData.account_holder_name = ''
        },
        changeRequiredRule(rule) {
            this.isRequireRule = rule
        },
        onChangeBank(bankId) {
            this.getAccountHolderName()
            const getSelectedBank = this.banks.find((el) => el.id === bankId)
            if (getSelectedBank?.supported_currency) {
                this.currency = getSelectedBank.supported_currency
            }
        },
        async onChangeLanguageString(code) {
            if (
                this.$cookie?.get('reg_loading') &&
                this.$cookie?.get('country_code') !== code
            ) {
                this.$store.commit('settings/changeLanguageLoading', true)
            } else {
                this.$cookie.set('reg_loading', true)
            }
            const filter = this.supportCountryAndLanguage.find(
                (el) => el.country_code === code
            )

            if (this.$cookie?.get('currency') !== filter?.currency_code) {
                this.$cookie.set('currency', filter?.currency_code)
            }

            this.$store.commit('settings/setGlobalFlag', filter?.country_code)

            if (this.$cookie?.get('country_code') !== code) {
                this.$cookie.set('country_code', filter.country_code)
                this.$store.commit(
                    'settings/setDefaultCurrency',
                    filter.currency_code
                )
                await this.globalBrowserDefaultLanguage()
            }
            await this.$store.dispatch('cms/cms', {})
            this.$store.commit('settings/changeLanguageLoading', false)
        },
        countryChange(e) {
            this.onChangeLanguageString(e)
            this.$store.dispatch('banks/banks', { country_code: e })
            const selectedCountry = this.supportCountryAndLanguage.find(
                (el) => el.country_code === e
            )
            if (selectedCountry) {
                const countryCurrency = selectedCountry.currency_code
                this.formData.currency = countryCurrency

                // fire to check suspend and player registration status
                this.onChangeCurrency(countryCurrency)
            }
        },
        async onChangeCurrency(currency) {
            // Fetch operation settings for checking suspend registration and registration setting
            this.suspendRegistrationLoading = true
            const response = await this.$store.dispatch(
                'settings/operationSetting',
                currency
            )
            this.suspendRegistrationLoading = false
            if (response) {
                const { suspend_reg: suspendRegistration } = response
                this.currencyIsSuspended = suspendRegistration
                if (suspendRegistration) {
                    this.$router.push('/')
                }
            }
        },
        async checkSuspendRegistration(currency) {
            const response = await this.$store.dispatch(
                'themes/player/checkSuspendRegistration',
                currency
            )

            if (response) {
                const { status } = response
                this.currencyIsSuspended = status
                if (status) {
                    this.$router.push('/')
                }
            }
        },
        isActiveLanguage(item) {
            return item?.supported_languages?.find(
                (el) => el?.is_active && el?.is_default
            )
        },
        onPlayerIdKeyDown(event) {
            // Valid characters for numbers and special symbols
            const validKeyPattern = /^[a-zA-Z0-9]$/

            // Allow common control/navigation keys, including most standard keys
            const isControlKey =
                event.key === 'Backspace' ||
                event.key === 'Tab' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Enter' ||
                event.key === 'Delete' ||
                event.key === 'Home' ||
                event.key === 'End' ||
                event.key === 'Escape' ||
                event.ctrlKey ||
                event.metaKey ||
                event.altKey

            // If it's not a valid number key or a control/navigation key, prevent the input
            if (!(isControlKey || validKeyPattern.test(event.key))) {
                event.preventDefault()
            }
        },
        async onUserRegister() {
            if (this.formData.player_id) {
                this.formData.player_id =
                    this.formData?.player_id?.toLowerCase()
                if (!PLAYER_REGEX.test(this.formData.player_id)) {
                    return
                }
            }
            try {
                this.registerLoading = true
                this.formData.recaptchaToken = await this.getRecaptchaToken(
                    'register'
                )

                this.formData.registrationOption = this.operationSettingRegFlow
                const { redirect, res } = await this.$store.dispatch(
                    'auth/registration',
                    this.formData
                )
                if (res) {
                    // Delay WebSocket initialization to ensure previous operations are fully completed
                    setTimeout(async () => {
                        await this.initSocketTheme()
                    }, 1000)
                    this.$router.push(redirect)
                } else {
                    this.resetRecaptcha()
                }
                this.registerLoading = false
            } catch (error) {
                this.registerLoading = false
            }
        },
    },
}
